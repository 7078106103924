import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ConfirmDialogData {
    title: string;
    text: string;
    confirm: string;
    isDanger?: boolean;
}

@Component({ templateUrl: './confirm-dialog.component.html' })
export class ConfirmDialog {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
    ) {}
}
