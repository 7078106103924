<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div>{{ data.text | translate }}</div>
    <sz-dialog-button-bar
        [primaryText]="data.confirm | translate"
        [primaryColor]="data.isDanger ? 'warn' : 'primary'"
        (primaryClick)="dialogRef.close(true)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
